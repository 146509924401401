import moment from 'moment'
import { commonErr } from "../components/common/errors"

export default function AppInitRed(appInit = {
    err: commonErr.INNIT,
    platformType: 0,
    data: {
        startTime: moment().valueOf(),
        endTime: moment().add(1, "days").valueOf()
    }
}, action) {
    switch (action.type) {
        case "SET_PLATFORM_TYPE": 
            return { 
                ...appInit, 
                platformType: action.payload
            }
        case "INIT_FULFILLED":
            if (action.payload.data.error >= commonErr.SUCCESS) {
                return {
                    ...appInit,
                    err: action.payload.data.error,
                    data: action.payload.data.data
                };
            }
            return appInit;

        case "INIT_PENDING":
            return {
                ...appInit,
                err: commonErr.LOADING
            }

        case "INIT_REJECTED":
            return {
                ...appInit,
                err: commonErr.FAIL
            }

        default:
            return appInit;
    }
}
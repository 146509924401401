import moment from "moment";
import axios from "axios";
import cookie from "react-cookies";

export const TPlatformType = {
  ALL: 0,
  BIZ: 1,
  AGENT: 2,
};

export const stypes = {
  SD: 1,
  WD: 2,
};

export const ServiceType = {
  1: "Tự lái",
  2: "Có tài",
};

export const wdModes = {
  CITY_TOUR: 0,
  PROVINCE: 1,
};

export const wdTypes = {
  CITY_TOUR: 1,
  ONEWAY: 2,
  TWOWAY: 3,
};

export const WDTypes = {
  0: "Chưa rõ",
  1: "Nội thành",
  2: "Liên tỉnh - 1 chiều",
  3: "Liên tỉnh - 2 chiều",
};

export const timeFilterModes = {
  DAY: 1,
  WEEK: 2,
  MONTH: 3,
};

export const genderMap = {
  0: "Chưa rõ",
  1: "Nam",
  2: "Nữ",
};

export const CarTransmission = {
  0: "Chưa rõ",
  1: "Số tự động",
  2: "Số sàn",
};

export const CarFuel = {
  0: "Chưa rõ",
  1: "Xăng",
  2: "Dầu diesel",
};

export const OrderStatus = {
  1: "Đang chờ đặt cọc",
  2: "Không tìm thấy xe",
  3: "Đã tìm được xe",
  4: "Không tìm thấy xe",
};

export const TripStatus = {
  1: "Đang chờ chủ duyệt",
  2: "Đang chờ đặt cọc",
  3: "Đang chờ đặt cọc",
  4: "Đã đặt cọc",
  5: "Đang cho thuê",
  20: "Chủ xe đã từ chối",
  21: "Chủ xe đã huỷ chuyến",
  22: "Chủ xe đã huỷ chuyến",
  23: "Khách thuê đã huỷ chuyến",
  24: "Khách thuê đã huỷ chuyến",
  25: "Chuyến đã kết thúc",
};

export const TOwnerTransactionType = {
  0: "",
  1: "Kết thúc chuyến đi",
  2: "Chủ xe hủy chuyến",
  3: "Khách thuê hủy chuyến",
  21: "Rút tiền",
  40: "Nạp tiền vào tài khoản",
  41: "Trừ tiền tài khoản",
};

export const CarStatus = {
  0: "Không xác định",
  1: "Chờ duyệt",
  2: "Đang hoạt động",
  3: "Đã bị từ chối",
  4: "Đã xoá",
  5: "Tạm ngưng hoạt động",
};

export const CarStatusColor = {
  0: "black",
  1: "orange",
  2: "green",
  3: "gray",
  4: "red",
  5: "gray",
};

export const getHCMCityCoordinates = {
  address: "Sài Gòn, Hồ Chí Minh, Việt Nam",
  lat: 10.8,
  lng: 106.7,
};

export const coordinates = {
  HCM: {
    lat: 10.8,
    lng: 106.7,
  },
  HN: {
    lat: 20.79753965,
    lng: 105.88674051,
  },
};

export const calendarTemplate =
  '<div class="daterangepicker dropdown-menu">' +
  '<div class="calendar left">' +
  '<div class="daterangepicker_input hidden">' +
  '<input class="input-mini form-control" type="text" name="daterangepicker_start" value="" />' +
  '<i class="fa fa-calendar glyphicon glyphicon-calendar"></i>' +
  '<div class="calendar-time">' +
  "<div></div>" +
  '<i class="fa fa-clock-o glyphicon glyphicon-time"></i>' +
  "</div>" +
  "</div>" +
  '<div class="calendar-table"></div>' +
  "</div>" +
  "</div>" +
  "</div>";

export function getOrderStatus(status) {
  switch (status) {
    case -740:
      return {
        class: "status black-dot",
        name: "Không tìm được xe phù hợp",
        badge: "badge-default",
      };
    case -1:
      return {
        class: "status orange-dot",
        name: "Bạn cần thanh toán cọc",
        badge: "badge-warning",
      };
    case 102:
      return {
        class: "status orange-dot",
        name: "Đang tìm xe",
        badge: "badge-warning",
      };
    case 200:
      return {
        class: "status green-dot",
        name: "Đã tìm được xe",
        badge: "badge-success",
      };
    case 4:
      return {
        class: "status green-dot",
        name: "Đã đặt cọc",
        badge: "badge-success",
      };
    case 5:
      return {
        class: "status green-dot",
        name: "Đang thuê",
        badge: "badge-success",
      };
    case 23:
    case 24:
      return {
        class: "status black-dot",
        name: "Chuyến đã bị hủy",
        badge: "badge-danger",
      };
    case 25:
      return {
        class: "status black-dot",
        name: "Chuyến đã kết thúc",
        badge: "badge-primary",
      };
    default:
      return {
        class: "status orange-dot",
        name: "Bạn cần thanh toán cọc",
        badge: "badge-warning",
      };
  }
}

export function getTripTime(trip) {
  if (trip.status >= 25) {
    return trip.return;
  } else if (trip.status >= 23) {
    return trip.timeCanceled;
  } else if (trip.status >= 21) {
    return trip.timeCanceled;
  } else if (trip.status >= 20) {
    return trip.timeCanceled;
  } else if (trip.status >= 5) {
    return trip.timePickup;
  } else if (trip.status >= 4) {
    return trip.timeDeposited;
  } else if (trip.status >= 2) {
    return trip.timeApproved;
  } else {
    return trip.timeBooked;
  }
}

export function formatPrice(price) {
  return Math.round(price / 1000) + "K";
}

export function arrayToString(data) {
  if (!data || data.length === 0) {
    return "";
  }
  var ret = "";
  for (var i = data.length - 1; i >= 0; --i) {
    if (ret === "") {
      ret = data[i];
    } else {
      ret += ";" + data[i];
    }
  }
  return ret;
}

export function arrayObjToString(data, key) {
  if (!data || data.length === 0) {
    return "";
  }
  var ret = "";
  for (var i = data.length - 1; i >= 0; --i) {
    if (ret === "") {
      ret = data[i][key];
    } else {
      ret += ";" + data[i][key];
    }
  }
  return ret;
}

export const carIconSmall = {
  path: "M18.9 5c-.2-.6-.8-1-1.4-1h-11c-.7 0-1.2.4-1.4 1L3 11v8c0 .6.4 1 1 1h1c.6 0 1-.4 1-1v-1h12v1c0 .6.4 1 1 1h1c.6 0 1-.4 1-1v-8l-2.1-6zM6.5 15c-.8 0-1.5-.7-1.5-1.5S5.7 12 6.5 12s1.5.7 1.5 1.5S7.3 15 6.5 15zm11 0c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5zM5 10l1.5-4.5h11L19 10H5z",
  fillColor: "#00a550",
  fillOpacity: 1,
  scale: 0.75,
};

export const carIcon = {
  path: "M18.9 5c-.2-.6-.8-1-1.4-1h-11c-.7 0-1.2.4-1.4 1L3 11v8c0 .6.4 1 1 1h1c.6 0 1-.4 1-1v-1h12v1c0 .6.4 1 1 1h1c.6 0 1-.4 1-1v-8l-2.1-6zM6.5 15c-.8 0-1.5-.7-1.5-1.5S5.7 12 6.5 12s1.5.7 1.5 1.5S7.3 15 6.5 15zm11 0c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5zM5 10l1.5-4.5h11L19 10H5z",
  fillColor: "#00a550",
  fillOpacity: 1,
  scale: 1.5,
};

export const notiType = {
  NTT_NULL: 0,
  NTT_ANNOUNCE: 1,
  NTT_BOOKING: 20,
  NTT_REQ: 80,
};

export function buildMonthCalendar(
  start,
  end,
  priceDaily,
  instantDays,
  unavailDays,
  bookedDays,
  priceSpecifics,
  priceRepeat,
  priceRepeatEndDate,
  isLimitRepeatPrice,
  unavailsRepeat,
  unavailsRepeatEndDate,
  isLimitUnavailsRepeat,
  setting
) {
  const days = [];
  const mapTripsMaxLevel = {};
  const _1dayInMSec = 24 * 3600000;
  var i, j;

  for (i = start.valueOf(); i <= end.valueOf(); i = i + _1dayInMSec) {
    const isToday = moment(i).isSame(moment(), "day");
    const isWeekend = moment(i).isoWeekday() === 7;
    var isPast = moment(i).isBefore(moment(), "day");
    var isOutside =
      moment(i).isBefore(start, "day") || end.isBefore(moment(i), "day");
    var isBooked = false;
    var bookedWidth;
    var bookedLeft;

    var isInstant = false;
    var isUnavail = false;
    var isUnavailRepeat = false;
    var price = priceDaily;
    var isPriceRepeat = false;

    var bookedTripId = "";
    var tripsReq = [];
    var tripsReqRet = [];

    for (j = 0; j < instantDays.length; ++j) {
      if (i === instantDays[j]) {
        isInstant = true;
        break;
      }
    }

    for (j = 0; j < unavailDays.length; ++j) {
      if (i === unavailDays[j]) {
        isUnavail = true;
        break;
      }
    }

    for (j = 0; j < bookedDays.length; ++j) {
      if (i === bookedDays[j].ts) {
        const trip = bookedDays[j];
        bookedTripId = trip.tripId;

        isBooked = true;
        bookedWidth = 100;
        bookedLeft = 0;

        if (moment(trip.startTs).isSame(moment(trip.endTs), "day")) {
          bookedWidth = ((trip.endTs - trip.startTs) * 100) / _1dayInMSec;
          bookedLeft =
            100 - ((i + _1dayInMSec - trip.startTs) * 100) / _1dayInMSec;
        } else if (
          moment(trip.endTs).isBetween(moment(i), moment(i + _1dayInMSec))
        ) {
          bookedWidth = ((trip.endTs - i) * 100) / _1dayInMSec;
        } else if (
          moment(trip.startTs).isBetween(moment(i), moment(i + _1dayInMSec))
        ) {
          bookedWidth = ((i + _1dayInMSec - trip.startTs) * 100) / _1dayInMSec;
          bookedLeft = 100 - bookedWidth;
        }
        break;
      }
    }

    for (j = 0; j < priceRepeat.length; ++j) {
      const weekday = priceRepeat[j];
      if (
        weekday.price > 0 &&
        moment(i).isoWeekday() === weekday.weekday &&
        (!isLimitRepeatPrice ||
          !priceRepeatEndDate ||
          priceRepeatEndDate === 0 ||
          i <= priceRepeatEndDate)
      ) {
        price = weekday.price;
        isPriceRepeat = true;
        break;
      }
    }

    for (j = 0; j < priceSpecifics.length; ++j) {
      if (i === priceSpecifics[j].ts) {
        price = priceSpecifics[j].price;
        break;
      }
    }

    for (j = 0; j < unavailsRepeat.length; ++j) {
      const weekday = unavailsRepeat[j];
      if (
        moment(i).isoWeekday() === weekday.weekday &&
        weekday.unavail === true &&
        (!isLimitUnavailsRepeat ||
          !unavailsRepeatEndDate ||
          unavailsRepeatEndDate === 0 ||
          i <= unavailsRepeatEndDate)
      ) {
        isUnavail = true;
        isUnavailRepeat = true;
        break;
      }
    }

    if (setting.waitApproves) {
      for (j = 0; j < setting.waitApproves.length; ++j) {
        tripsReq.push({ ...setting.waitApproves[j], isApproved: false });
      }
    }
    if (setting.waitDeposits) {
      for (j = 0; j < setting.waitDeposits.length; ++j) {
        tripsReq.push({ ...setting.waitDeposits[j], isApproved: true });
      }
    }
    tripsReq = tripsReq.sort(function (trip1, trip2) {
      return trip1.startTime - trip2.startTime;
    });

    for (j = 0; j < tripsReq.length; ++j) {
      const trip = tripsReq[j];
      if (
        moment(i).isSame(moment(trip.startTime).startOf("day")) ||
        moment(i).isSame(moment(trip.endTime).startOf("day")) ||
        moment(i).isBetween(
          moment(trip.startTime).startOf("day"),
          moment(trip.endTime).endOf("day")
        )
      ) {
        const isStart = moment(i).isSame(moment(trip.startTime), "day");
        var width = 100;
        var left = 0;

        if (moment(trip.startTime).isSame(moment(trip.endTime), "day")) {
          width = ((trip.endTime - trip.startTime) * 100) / _1dayInMSec;
          left = 100 - ((i + _1dayInMSec - trip.startTime) * 100) / _1dayInMSec;
        } else if (
          moment(trip.endTime).isBetween(moment(i), moment(i + _1dayInMSec))
        ) {
          width = ((trip.endTime - i) * 100) / _1dayInMSec;
        } else if (
          moment(trip.startTime).isBetween(moment(i), moment(i + _1dayInMSec))
        ) {
          width = ((i + _1dayInMSec - trip.startTime) * 100) / _1dayInMSec;
          left = 100 - width;
        }

        tripsReqRet.push({
          tripInfo: trip,
          width: width,
          left: left,
          isStart: isStart,
        });

        var level = 1;
        for (var k = 0; k < j; ++k) {
          const stackedTrip = tripsReq[k];
          if (trip.startTime <= stackedTrip.endTime) {
            if (mapTripsMaxLevel[stackedTrip.tripId]) {
              level = mapTripsMaxLevel[stackedTrip.tripId] + 1;
            } else {
              level = level + 1;
            }
          }
        }

        if (
          !mapTripsMaxLevel[trip.tripId] ||
          level > mapTripsMaxLevel[trip.tripId]
        ) {
          mapTripsMaxLevel[trip.tripId] = level;
        }
      }
    }

    days.push({
      ts: i,
      isToday: isToday,
      isWeekend: isWeekend,
      isPast: isPast,
      isOutside: isOutside,
      isBooked: isBooked,
      bookedTripId: bookedTripId,
      bookedLeft: bookedLeft,
      bookedWidth: bookedWidth,
      isInstant: isInstant,
      isUnavail: isUnavail,
      isUnavailRepeat: isUnavailRepeat,
      price: price,
      isPriceRepeat: isPriceRepeat,
      tripsReq: tripsReqRet,
      mapTripsMaxLevel: mapTripsMaxLevel,
    });
  }

  return days;
}

export const toAlias = (str) => {
  str = trim(str);
  let replaceChr = "-";
  let stripped_str = str;
  stripped_str = stripped_str.replace(/,/g, "");
  let viet = [];
  let i = 0;
  viet[i++] = ["a", "/á|à|ả|ã|ạ|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/g"];
  viet[i++] = ["o", "/ó|ò|ỏ|õ|ọ|ơ|ớ|ờ|ở|ỡ|ợ|ô|ố|ồ|ổ|ỗ|ộ/g"];
  viet[i++] = ["e", "/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/g"];
  viet[i++] = ["u", "/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/g"];
  viet[i++] = ["i", "/í|ì|ỉ|ĩ|ị/g"];
  viet[i++] = ["y", "/ý|ỳ|ỷ|ỹ|ỵ/g"];
  viet[i++] = ["d", "/đ/g"];
  for (let i = 0; i < viet.length; i++) {
    // eslint-disable-next-line
    stripped_str = stripped_str.replace(eval(viet[i][1]), viet[i][0]); // eslint-disable-next-line
    stripped_str = stripped_str.replace(
      eval(viet[i][1].toUpperCase().replace("G", "g")),
      viet[i][0].toUpperCase()
    );
  }
  if (replaceChr) {
    // eslint-disable-next-line
    return stripped_str
      .replace(/[\W]|_/g, replaceChr)
      .replace(/\s/g, replaceChr)
      .replace(/^\-+|\-+$/g, replaceChr);
  } else {
    return stripped_str;
  }
};

export const trim = (str) => {
  return str.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "");
};

export function formatTitleInUrl(title) {
  return toAlias(
    trim(
      title
        .trim()
        .toLowerCase()
        .split(" ")
        .join("_")
        .split("?")
        .join("")
        .split("&")
        .join("")
        .split(",")
        .join("")
        .split(".")
        .join("")
        .split(";")
        .join("")
        .split("/")
        .join("")
    )
  );
}

export function convertToDateTimeObj(time) {
  time = moment(time).valueOf();

  var days = Math.floor(time / (1000 * 60 * 60 * 24));
  if (days < 10) {
    days = "0" + days;
  }
  var hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  if (hours < 10) {
    hours = "0" + hours;
  }
  var minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  var seconds = Math.floor((time % (1000 * 60)) / 1000);
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
}

export function getCountDownTime(time) {
  time = moment(time).valueOf();
  var now = moment().valueOf();

  return time - now;
}

export function convertToDateTimeText(time) {
  time = moment(time).valueOf();
  if (time < 0) {
    return "Thời gian còn lại: Quá hạn";
  }

  var dateTimeObj = convertToDateTimeObj(time);
  return (
    "Thời gian còn lại: " +
    (dateTimeObj.days > 0 ? dateTimeObj.days + " ngày " : "") +
    (dateTimeObj.hours > 0 ? dateTimeObj.hours + " tiếng " : "") +
    (dateTimeObj.minutes > 0 ? dateTimeObj.minutes + " phút " : "") +
    dateTimeObj.seconds +
    " giây"
  );
}

export const VNCitiesMap = [
    { key: "HoChiMinh", value: "TP Hồ Chí Minh" },
    { key: "HaNoi", value: "Hà Nội" },
    { key: "DaNang", value: "Đà Nẵng" },
    { key: "BinhDuong", value: "Bình Dương" },
    { key: "CanTho", value: "Cần Thơ" },
    { key: "DaLat", value: "Đà Lạt" },
    { key: "NhaTrang", value: "Nha Trang" },
    { key: "QuyNhon", value: "Quy Nhơn" },
    { key: "PhuQuoc", value: "Phú Quốc" },
    { key: "HaiPhong", value: "Hải Phòng" },
    { key: "VungTau", value: "Vũng Tàu" },
    { key: "Others", value: "Thành phố khác" },

    // { key: "AnGiang", value: "An Giang" },
    // { key: "BaRiaVungTau", value: "Bà Rịa - Vũng Tàu" },
    // { key: "BacGiang", value: "Bắc Giang" },
    // { key: "BacKan", value: "Bắc Kạn" },
    // { key: "BacLieu", value: "Bạc Liêu" },
    // { key: "BacNinh", value: "Bắc Ninh" },
    // { key: "BenTre", value: "Bến Tre" },
    // { key: "BinhDinh", value: "Bình Định" },
    // { key: "BinhPhuoc", value: "Bình Phước" },
    // { key: "BinhThuan", value: "Bình Thuận" },
    // { key: "CaMau", value: "Cà Mau" },
    // { key: "CaoBang", value: "Cao Bằng" },
    // { key: "DakLak", value: "Đắk Lắk" },
    // { key: "DakNong", value: "Đắk Nông" },
    // { key: "DienBien", value: "Điện Biên" },
    // { key: "DongNai", value: "Đồng Nai" },
    // { key: "DongThap", value: "Đồng Tháp" },
    // { key: "GiaLai", value: "Gia Lai" },
    // { key: "HaGiang", value: "Hà Giang" },
    // { key: "HaNam", value: "Hà Nam" },
    // { key: "HaTinh", value: "Hà Tĩnh" },
    // { key: "HaiDuong", value: "Hải Dương" },
    // { key: "HauGiang", value: "Hậu Giang" },
    // { key: "HaiPhong", value: "Hải Phòng" },
    // { key: "HoaBinh", value: "Hòa Bình" },
    // { key: "HungYen", value: "Hưng Yên" },
    // { key: "KhanhHoa", value: "Khánh Hòa" },
    // { key: "KienGiang", value: "Kiên Giang" },
    // { key: "KonTum", value: "Kon Tum" },
    // { key: "LaiChau", value: "Lai Châu" },
    // { key: "LamDong", value: "Lâm Đồng" },
    // { key: "LangSon", value: "Lạng Sơn" },
    // { key: "LaoCai", value: "Lào Cai" },
    // { key: "LongAn", value: "Long An" },
    // { key: "NamDinh", value: "Nam Định" },
    // { key: "NgheAn", value: "Nghệ An" },
    // { key: "NinhBinh", value: "Ninh Bình" },
    // { key: "NinhThuan", value: "Ninh Thuận" },
    // { key: "PhuTho", value: "Phú Thọ" },
    // { key: "PhuYen", value: "Phú Yên" },
    // { key: "QuangBinh", value: "Quảng Bình" },
    // { key: "QuangNam", value: "Quảng Nam" },
    // { key: "QuangNgai", value: "Quảng Ngãi" },
    // { key: "QuangNinh", value: "Quảng Ninh" },
    // { key: "QuangTri", value: "Quảng Trị" },
    // { key: "SocTrang", value: "Sóc Trăng" },
    // { key: "SonLa", value: "Sơn La" },
    // { key: "TayNinh", value: "Tây Ninh" },
    // { key: "ThaiBinh", value: "Thái Bình" },
    // { key: "ThaiNguyen", value: "Thái Nguyên" },
    // { key: "ThanhHoa", value: "Thanh Hóa" },
    // { key: "ThuaThienHue", value: "Thừa Thiên Huế" },
    // { key: "TienGiang", value: "Tiền Giang" },
    // { key: "TraVinh", value: "Trà Vinh" },
    // { key: "TuyenQuang", value: "Tuyên Quang" },
    // { key: "VinhLong", value: "Vĩnh Long" },
    // { key: "VinhPhuc", value: "Vĩnh Phúc" },
    // { key: "YenBai", value: "Yên Bái" }
]


export function isPhoneValid(phoneNumber) {
  var isValid = false;
  var phone = phoneNumber.trim();
  phone = phone.replace("(+84)", "0");
  phone = phone.replace("+84", "0");
  phone = phone.replace("0084", "0");
  phone = phone.replace(/ /g, "");
  if (phone != "") {
    var firstNumber = phone.substring(0, 2);
    if (phone.length == 10) {
      if (phone.match(/^\d{10}/)) {
        isValid = true;
      }
    } else if (firstNumber == "01" && phone.length == 11) {
      if (phone.match(/^\d{11}/)) {
        isValid = true;
      }
    }
  }
  return isValid;
}

export function isEmailValid(email) {
  var isValid = false;
  var regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (regex.test(email)) {
    isValid = true;
  }
  return isValid;
}

export const DEFAULT_INSTANT_RANGE_FROM = 21600;

export const DEFAULT_INSTANT_RANGE_TO = 1209600;

export const listInstantRangeFrom = [
  { key: 21600, value: "6 tiếng tới" },
  { key: 43200, value: "12 tiếng tới" },
  { key: 86400, value: "24 tiếng tới" },
];

export const listInstantRangeTo = [
  { key: 604800, value: "1 tuần tới" },
  { key: 1209600, value: "2 tuần tới (Khuyến nghị)" },
  { key: 1814400, value: "3 tuần tới" },
  { key: 2419200, value: "4 tuần tới" },
];

export const DEFAULT_AVAIL_FROM = 0;

export const DEFAULT_AVAIL_TO = 12960000;

export const listAvailFrom = [{ key: 0, value: "Hiện tại" }];

export const listAvailTo = [
  { key: 2592000, value: "1 tháng tới" },
  { key: 5184000, value: "2 tháng tới" },
  { key: 7776000, value: "3 tháng tới (khuyến nghị)" },
  { key: 12960000, value: "5 tháng tới" },
];

export function convertSecondToTime(second) {
  if (second < 3600) {
    return "Hiện tại";
  }
  if (second / 3600 > 24) {
    if (second / (3600 * 24) > 7) {
      if (second / (3600 * 24 * 30) >= 1) {
        return second / (3600 * 24 * 30) + " tháng tới";
      } else {
        return second / (3600 * 24 * 7) + " tuần tới";
      }
    } else {
      return second / (3600 * 24) > 7 + " ngày tới";
    }
  } else {
    return second / 3600 + " giờ tới";
  }
}

export function convertTimeToRecent(timeInMilliseconds) {
  if (timeInMilliseconds < moment().valueOf()) {
    return moment(timeInMilliseconds).fromNow();
  }
  return moment(timeInMilliseconds).toNow();
}

export function getLoginPlatform(url) {
  const localDev = "local.dev.mioto.vn";
  let subName = url.includes("-dev")
    ? "-dev"
    : url.includes("-stag")
    ? "-stag"
    : "";

  if (url === `biz${subName}.mioto.vn` || url === localDev) {
    return TPlatformType.BIZ;
  }

  if (url === `agent${subName}.mioto.vn`) {
    return TPlatformType.AGENT;
  }

  return TPlatformType.ALL;
}

export function getPlatformAccountType(accountType, url) {
  let isAccountBiz = accountType.accountBusiness === 1;
  let isAccountAgent = accountType.accountAgency === 1;

  if (!accountType) {
    return TPlatformType.ALL;
  }

  if (!isAccountBiz && !isAccountAgent) {
    return TPlatformType.ALL;
  }

  if (isAccountBiz && getLoginPlatform(url) === TPlatformType.BIZ) {
    return TPlatformType.BIZ;
  }

  if (isAccountAgent && getLoginPlatform(url) === TPlatformType.AGENT) {
    return TPlatformType.AGENT;
  }

  return TPlatformType.ALL;
}

export function trackCarRegisterToAccessTrade(regId) {
  const trackingId = cookie.load("_aff_sid") || "";
  if (trackingId === "") return;

  window.AT.init({ campaign_id: 2286, is_reoccur: 1, is_lastclick: 1 });
  let accesstrade_order_info = {
    order_id: regId,
    amount: 0,
    discount: 0,
    extra__phone: "default",
    order_items: [
      {
        itemid: "default",
        quantity: 1,
        price: 0,
        catid: "cpql",
      },
    ],
  };
  window.AT.track_order(accesstrade_order_info);
}

import React from "react";
import cookie from "react-cookies";
import queryString from "query-string";
import { Modal } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import Header from "../common/header";
import Footer from "../common/footer";

import { submitFormOwnerRegister } from "../../model/common";

import { Register, Car, Account, IcErrorInput, IcSuccessModal } from "../common/svg";
import { VNCitiesMap, isPhoneValid } from "../common/common";
import { commonErr } from "../common/errors";

import bg_bottom from "../../static/images/intro-register-owner/bg-bottom.png";
import bg_top from "../../static/images/intro-register-owner/bg-top.png";
import bg_content from "../../static/images/intro-register-owner/bg-content-banner.png";
import reason1 from "../../static/images/intro-register-owner/reason1.svg";
import reason2 from "../../static/images/intro-register-owner/reason2.svg";
import reason3 from "../../static/images/intro-register-owner/reason3.svg";
import reason4 from "../../static/images/intro-register-owner/reason4.svg";
import reason5 from "../../static/images/intro-register-owner/reason5.svg";
import reason6 from "../../static/images/intro-register-owner/reason6.svg";

const REASON_ITEM = [
	{
		id: 1,
		icon: reason1,
		title: "Thu nhập",
		desc: `Gia tăng thu nhập từ <strong>5-10 triệu/tháng.</strong>`,
	},
	{
		id: 2,
		icon: reason2,
		title: "Nhanh chóng",
		desc: `Thủ tục đăng ký cho thuê <strong>ONLINE </strong> nhanh chóng trong <strong>10 phút</strong>`,
	},
	{
		id: 3,
		icon: reason3,
		title: "Chủ động",
		desc: `Toàn quyền quyết định <strong>giá &amp; thời gian </strong> cho thuê.`,
	},
	{
		id: 4,
		icon: reason4,
		title: "Bảo hiểm",
		desc: `Hỗ trợ bảo hiểm mất cắp xe nguyên chiếc từ <strong>MIC &amp; PJICO</strong>`,
	},
	{
		id: 5,
		icon: reason5,
		title: "GPS",
		desc: `Hỗ trợ cài đặt GPS với giá gốc để quản lí xe an toàn.`,
	},
	{
		id: 6,
		icon: reason6,
		title: "Chăm sóc đối tác",
		desc: `Đội ngũ Mioto tư vấn chặt chẽ <strong>QUY TRÌNH &amp; KINH NGHIỆM </strong> cho thuê chặt chẽ và an toàn`,
	},
];

const REASON_STEP = [
	{
		id: 1,
		icon: <Register />,
		title: "Tải app và điền thông tin",
		desc: `Cách 1: Tải app Mioto, vào mục Xe của tôi và đăng kí xe theo hướng dẫn.<br />Cách 2: Điền thông tin theo mẫu để Mioto hỗ trợ tư vấn quy trình đăng kí.`,
	},
	{
		id: 2,
		icon: <Account />,
		title: "Xác nhận thông tin",
		desc: `Nhân viên Mioto liên hệ chủ xe tư vấn thủ tục &amp; quy trình cho thuê xe trong vòng 1 ngày sau khi nhận được thông tin.`,
	},
	{
		id: 3,
		icon: <Car />,
		title: "Duyệt xe",
		desc: `Bắt đầu cho thuê xe trên Mioto sau khi nhận thông báo xe đã được phê duyệt.`,
	},
];

const OwnerRegisterReason = () => {
	return (
		<>
			<div className="list-reason isOffTablet">
				{REASON_ITEM.map((reason) => (
					<div className="reason-item" key={reason.id}>
						<div className="green-card-option">
							<div className="horizontal-content">
								<div className="horizontal-content--content">
									<h6>{reason.title}</h6>
									<p dangerouslySetInnerHTML={{ __html: reason.desc }} />
								</div>
								<div className="horizontal-content--img">
									<img src={reason.icon} />
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
			<Swiper
				className="isShowTablet swiper-intro-register"
				slidesPerView={1.5}
				spaceBetween={15}
				modules={[Pagination]}
				pagination={{
					el: ".swiper-pagination",
					clickable: true,
					dynamicBullets: true,
				}}>
				{REASON_ITEM.map((reason) => (
					<SwiperSlide key={reason.id}>
						<div className="green-card-option reason-item">
							<div className="horizontal-content">
								<div className="horizontal-content--content">
									<h6>{reason.title}</h6>
									<p dangerouslySetInnerHTML={{ __html: reason.desc }} />
								</div>
								<div className="horizontal-content--img">
									<img src={reason.icon} />
								</div>
							</div>
						</div>
					</SwiperSlide>
				))}
				<div className="swiper-pagination" />
			</Swiper>
		</>
	);
};

const OwnerRegisterStep = () => {
	return (
		<>
			<div className="list-reason isOffTablet">
				{REASON_STEP.map((step) => (
					<div className="reason-item" key={step.id}>
						<div className="green-card-option">
							<div className="number">{step.id}.</div>
							<div className="wrap-svg">{step.icon}</div>
							<h6>{step.title}</h6>
							<p dangerouslySetInnerHTML={{ __html: step.desc }} />
						</div>
					</div>
				))}
			</div>
			<Swiper
				className="isShowTablet swiper-intro-register"
				slidesPerView={1.5}
				spaceBetween={15}
				modules={[Pagination]}
				pagination={{
					el: ".swiper-pagination",
					clickable: true,
					dynamicBullets: true,
				}}>
				{REASON_STEP.map((step) => (
					<SwiperSlide key={step.id}>
						<div className="reason-item green-card-option">
							<div className="number">{step.id}.</div>
							<div className="wrap-svg">{step.icon}</div>
							<h6>{step.title}</h6>
							<p dangerouslySetInnerHTML={{ __html: step.desc }} />
						</div>
					</SwiperSlide>
				))}
				<div className="swiper-pagination" />
			</Swiper>
		</>
	);
};

class ModalOwnerRegister extends React.Component {
	constructor() {
		super();
		this.state = {
			err: commonErr.INNIT,
			errMsg: "",
			city: "",
			name: "",
			phoneNumber: "",
			carName: "",
			isCityFilled: true,
			isNameFilled: true,
			isPhoneFilled: true,
		};
	}

	handleInputChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	refreshCheckFilled = () => {
		this.setState({
			isCityFilled: true,
			isNameFilled: true,
			isPhoneFilled: true,
		});
	};

	isValidInfo = () => {
		const { city, name, phoneNumber } = this.state;
		this.refreshCheckFilled();
		if (city === "" || !city) {
			this.setState({
				isCityFilled: false,
			});
			return false;
		}
		if (name === "" || !name) {
			this.setState({
				isNameFilled: false,
			});
			return false;
		}
		if (phoneNumber === "" || !phoneNumber || !isPhoneValid(phoneNumber)) {
			this.setState({
				isPhoneFilled: false,
			});
			return false;
		}
		return true;
	};

	onSubmitFormRegister = () => {
		const { city, name, phoneNumber, carName } = this.state;
		const { toggleRegister, toggleSuccess } = this.props;
		const valid = this.isValidInfo();
		if (valid) {
			const data = {
				name: name,
				phoneNumber: phoneNumber,
				city: city,
				email: "",
				isRental: 0,
				reference: "",
				carName: carName,
				stype: 1,
			};

			submitFormOwnerRegister(data).then((resp) => {
				if (resp.data.error === commonErr.SUCCESS) {
					this.setState({
						err: resp.data.error,
					});
					this.close();
					toggleSuccess();
				}
			});
		}

		// this.props.toggleRegister();
	};

	close = () => {
		this.props.hideModal();
	};

	render() {
		const { city, phoneNumber, name, carName, isCityFilled, isNameFilled, isPhoneFilled } = this.state;
		const { show, toggleRegister } = this.props;

		return (
			<Modal show={show} onHide={this.close} dialogClassName="modal-m modal-dialog modal-input">
				<Modal.Header closeButton={true} closeLabel={""}>
					<h4 className="text-primary">Đăng ký xe cho thuê</h4>
					<p className="sub-title">
						Bạn vui lòng điền đầy đủ thông tin, MIOTO sẽ liên hệ vói bạn trong vòng một ngày làm&nbsp;việc.
					</p>
				</Modal.Header>
				<Modal.Body>
					<div className="modal-input-body modal-owner-register">
						<div className="line-form">
							<div className="custom-input">
								{isCityFilled ? (
									<div className="wrap-info">
										<div className="title-status">
											<p>Khu vực cho thuê</p>
										</div>
									</div>
								) : (
									<div className="wrap-info">
										<div className="title-status">
											<p>Khu vực cho thuê</p>
											<div className="wrap-svg">
												<IcErrorInput />
											</div>
										</div>
										<div className="desc text-danger">Không hợp lệ</div>
									</div>
								)}
								<div className="custom-select">
									<select
										className={isCityFilled ? "" : "error"}
										onChange={this.handleInputChange}
										required
										name="city"
										defaultValue={city}>
										<option value="" disabled hidden>
											Chọn khu vực
										</option>
										{VNCitiesMap.map((city) => (
											<option key={city.key} value={city.key}>
												{city.value}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>
						<div className="line-form">
							<div className="custom-input">
								{isNameFilled ? (
									<div className="wrap-info">
										<div className="title-status">
											<p>Tên chủ xe*</p>
										</div>
									</div>
								) : (
									<div className="wrap-info">
										<div className="title-status">
											<p>Tên chủ xe*</p>
											<div className="wrap-svg">
												<IcErrorInput />
											</div>
										</div>
										<div className="desc text-danger">Không hợp lệ</div>
									</div>
								)}
								<div className="wrap-input">
									<input
										value={name}
										type="text"
										placeholder="Tên của bạn"
										name="name"
										onChange={this.handleInputChange}
									/>
								</div>
							</div>
							<div className="custom-input">
								{isPhoneFilled ? (
									<div className="wrap-info">
										<div className="title-status">
											<p>Số di động*</p>
										</div>
									</div>
								) : (
									<div className="wrap-info">
										<div className="title-status">
											<p>Số di động*</p>
											<div className="wrap-svg">
												<IcErrorInput />
											</div>
										</div>
										<div className="desc text-danger">Không hợp lệ</div>
									</div>
								)}
								<div className="wrap-input">
									<input
										value={phoneNumber}
										type="text"
										placeholder="Số của bạn"
										name="phoneNumber"
										onChange={this.handleInputChange}
									/>
								</div>
							</div>
						</div>
						<div className="line-form">
							<div className="custom-input">
								<div className="wrap-info">
									<div className="title-status">
										<p>Xe cho thuê</p>
									</div>
								</div>
								<div className="wrap-input">
									<input
										value={carName}
										type="text"
										placeholder="Loại xe của bạn"
										name="carName"
										onChange={this.handleInputChange}
									/>
								</div>
							</div>
						</div>
						<div className="wrap-btn btn-register">
							<a onClick={this.onSubmitFormRegister} className="btn btn--m btn-primary">
								Gửi thông tin đến MIOTO
							</a>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		);
	}
}

const ModalSuccess = ({ isShowSuccess, toggleSuccess }) => {
	return (
		<Modal show={isShowSuccess} onHide={toggleSuccess} dialogClassName="modal-m modal-dialog modal-text">
			<Modal.Header closeButton={true} closeLabel={""}>
				<div className="wrap-svg">
					<IcSuccessModal />
				</div>
				<h5>Gửi yêu cầu thành công</h5>
			</Modal.Header>
			<Modal.Body>
				<div className="modal-text-body">
					<p className="font-14">Yêu cầu đăng kí cho thuê xe của bạn đã được gửi.</p>
					<p className="font-14">
						Bộ phận phát triển kinh doanh của Mioto sẽ liên hệ bạn để tư vấn quy trình và hướng dẫn đăng xe trong thời
						gian sớm nhất. Bạn cũng có thể đăng xe trực tiếp bằng cái tải ứng dụng Mioto và đăng xe theo hướng dẫn tại
						mục "Xe của tôi".
					</p>
					<p className="font-14">Để được hỗ trợ, vui lòng gọi hotline 19009217 (Hỗ trợ 7AM - 10PM).</p>
					<p className="font-14">Xin cám ơn,</p>
					<p className="font-14">MIOTO TEAM</p>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default class OwnerRegister extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isShowRegister: false,
			isShowSuccess: false,
		};
	}

	componentDidMount() {
		if (this.props.location && this.props.location.search) {
			const query = queryString.parse(this.props.location.search);

			if (query.utm_source) {
				cookie.save("_utm_src", query.utm_source, { path: "/" });
				cookie.save("_utm_src", query.utm_source, {
					path: "/",
					domain: ".mioto.vn",
				});
			}
			if (query.utm_ext) {
				cookie.save("_utm_ext", query.utm_ext, { path: "/" });
				cookie.save("_utm_ext", query.utm_ext, {
					path: "/",
					domain: ".mioto.vn",
				});
			} else {
				cookie.remove("_utm_ext", { path: "/" });
				cookie.remove("_utm_ext", { path: "/", domain: ".mioto.vn" });
			}
		}
		window.scrollTo(0, 0);
	}

	showRegister = () => {
		this.setState({
			isShowRegister: true,
		});
	};

	hideRegister = () => {
		this.setState({
			isShowRegister: false,
		});
	};

	toggleSuccess = () => {
		this.setState({
			isShowSuccess: !this.state.isShowSuccess,
		});
	};

	render() {
		const { isShowRegister, isShowSuccess } = this.state;
		var content;
		content = (
			<section className="body">
				<div className="intro-register-owner">
					<div className="banner-top-sect">
						<div className="m-container">
							<div
								className="background space sec"
								style={{
									backgroundImage: `url(${bg_top})`,
								}}
							/>
							<div className="content-container">
								<img className="bg-content" src={bg_content} />
								<div className="main-content">
									<h1>
										<span className="text-success">Cho Thuê Xe </span>
										Trên Mioto Để Gia Tăng Thu Nhập Đến 10tr/Tháng!
									</h1>
									<p>
										Mioto không thu phí khi bạn đăng xe. Bạn chỉ chia sẻ phí dịch vụ với Mioto khi có giao dịch cho thuê
										thành công.
									</p>
									<div className="line" />
									<p>Hotline: 1900 9217 (Hỗ trợ 7AM - 10PM)</p>
									<p>Hoặc để lại tin nhắn cho Mioto qua Fanpage</p>
									<a onClick={this.showRegister} className="btn btn--s btn-black">
										Đăng ký ngay
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="register-owner-why space sec">
						<div className="m-container">
							<h2>
								Tại sao bạn nên cho thuê xe
								<br />
								trên MIOTO?
							</h2>
							<OwnerRegisterReason />
						</div>
					</div>
					<div className="estimated-income space sec">
						<div className="m-container">
							<h2>
								Thu nhập ước tính
								<br />
								của chủ xe
							</h2>
							<p className="sub-title">
								Mioto dựa trên dữ liệu thu nhập bình quân 6 tháng gần nhất của các chủ xe đang kinh doanh hiệu quả trên
								hệ thống và thống kê theo từng khu vực.
							</p>
							<div className="estimated-income__table">
								<div className="table-wrap">
									<div className="box-content__first"> </div>
									<div className="box-content">
										<h6>Thu nhập /tháng</h6>
										<p className="note">(Sau khi trừ phí dịch vụ)</p>
									</div>
									<div className="box-content">
										<h6>Số ngày cho thuê/tháng</h6>
									</div>
								</div>
								<div className="table-wrap">
									<div className="box-content__first">
										<p>TP.HCM (khu vực trung tâm)</p>
									</div>
									<div className="box-content">
										<p>Từ 5 - 10 triệu</p>
									</div>
									<div className="box-content">
										<p>Từ 6 - 12 ngày</p>
									</div>
								</div>
								<div className="table-wrap">
									<div className="box-content__first">
										<p>TP.HCM (khu vực ngoại thành)</p>
									</div>
									<div className="box-content">
										<p>3 - 6</p>
									</div>
									<div className="box-content">
										<p>4 - 8</p>
									</div>
								</div>
								<div className="table-wrap">
									<div className="box-content__first">
										<p>Hà Nội (khu vực trung tâm)</p>
									</div>
									<div className="box-content">
										<p>5 - 8</p>
									</div>
									<div className="box-content">
										<p>6 - 10</p>
									</div>
								</div>
								<div className="table-wrap">
									<div className="box-content__first">
										<p>Hà Nội (khu vực ngoại thành)</p>
									</div>
									<div className="box-content">
										<p>3 - 6</p>
									</div>
									<div className="box-content">
										<p>4 - 8</p>
									</div>
								</div>
								<div className="table-wrap">
									<div className="box-content__first">
										<p>Đà Nẵng</p>
									</div>
									<div className="box-content">
										<p>3 - 6</p>
									</div>
									<div className="box-content">
										<p>4 - 8</p>
									</div>
								</div>
								<div className="table-wrap">
									<div className="box-content__first">
										<p>Bình Dương</p>
									</div>
									<div className="box-content">
										<p>3 - 6</p>
									</div>
									<div className="box-content">
										<p>4 - 8</p>
									</div>
								</div>
								<div className="table-wrap">
									<div className="box-content__first">
										<p>Đà Lạt</p>
									</div>
									<div className="box-content">
										<p>3 - 6</p>
									</div>
									<div className="box-content">
										<p>4 - 8</p>
									</div>
								</div>
								<div className="table-wrap">
									<div className="box-content__first">
										<p>Phú Quốc</p>
									</div>
									<div className="box-content">
										<p>3 - 6</p>
									</div>
									<div className="box-content">
										<p>4 - 8</p>
									</div>
								</div>
								<div className="table-wrap">
									<div className="box-content__first">
										<p>TP khác</p>
									</div>
									<div className="box-content">
										<p>2 - 5</p>
									</div>
									<div className="box-content">
										<p>3 - 6</p>
									</div>
								</div>
							</div>
							<p className="estimated-income__note textAlign-center">
								* Lưu ý: Thu nhập của chủ xe khi cho thuê trên Mioto còn tùy thuộc vào nhiều yếu tố, bao gồm thời gian
								xe sẵn sàng cho thuê mỗi tháng, mức giá cho thuê và nhu cầu thuê xe ở khu vực của bạn.
							</p>
						</div>
					</div>
					<div className="registration-steps space sec">
						<div className="m-container">
							<h2>Ba bước đăng ký xe trên MIOTO</h2>
							<OwnerRegisterStep />
						</div>
					</div>
					<div className="banner-bottom">
						<img src={bg_bottom} />
						<div className="main-content">
							<h2>Trở thành đối tác chủ xe của MIOTO</h2>
							<p>Cho thuê xe trên Mioto để gia tăng thu nhập hàng tháng và gặp gỡ nhiều bạn bè mới!</p>
							<a onClick={this.showRegister} className="btn btn--m btn-primary">
								Đăng ký ngay
							</a>
						</div>
					</div>
				</div>
			</section>
		);

		return (
			<div className="mioto-layout">
				<Header />
				{content}
				<Footer />
				<ModalOwnerRegister
					show={isShowRegister}
					isShowSuccess={isShowSuccess}
					hideModal={this.hideRegister}
					toggleSuccess={this.toggleSuccess}
				/>
				<ModalSuccess isShowSuccess={isShowSuccess} toggleSuccess={this.toggleSuccess} />
			</div>
		);
	}
}

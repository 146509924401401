import React, { useState } from "react";

import logo_full from "../../static/images/logo/logo-full.png";
import logo_short from "../../static/images/logo/logo-short.png";
import { IcHamburger } from "./svg";

const Header = () => {
	const [menu, setMenu] = useState(false);

	return (
		<section className="header">
			<div className="m-container">
				<a className="logo-container" href="https://www.mioto.vn/">
					<div className="full-logo">
						<img src={logo_full} alt="Mioto" />
					</div>
					<div className="short-logo">
						<img src={logo_short} alt="Mioto" />
					</div>
				</a>
				<div className="menu-container">
					<a href="https://www.mioto.vn/aboutus" className="menu-list__item">
						Về Mioto
					</a>
					<a href="https://www.mioto.vn/howitwork" className="menu-list__item">
						Hướng dẫn chung
					</a>
					<a href="https://www.mioto.vn/blogs" className="menu-list__item">
						Blog
					</a>
					<div className="dropdown-profile">
						<div className="wrap-svg tab-menu" onClick={() => setMenu(!menu)}>
							<IcHamburger />
						</div>
					</div>
					<div className={`menu-mobile ${menu ? "open" : "open move-left"}`}>
						<div className="header-menu">
							<div className="close-menu" onClick={() => setMenu(!menu)}>
								×
							</div>
						</div>
						<div className="m-container">
							<div className="menu-list">
								<a href="https://www.mioto.vn/aboutus" className="menu-list__item">
									Về Mioto
								</a>
								<div className="line-page" />
								<a href="https://www.mioto.vn/howitwork" className="menu-list__item">
									Hướng dẫn chung
								</a>
								<div className="line-page" />
								<a href="https://www.mioto.vn/blogs" className="menu-list__item">
									Blog
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Header;

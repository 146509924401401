import { commonErr } from "../components/common/errors";

export default function SessionRed(session = {
    loggin: {
        err: commonErr.INNIT
    },
    profile: {
        err: commonErr.INNIT,
        info: {
        }
    }
}, action) {
    switch (action.type) {
        case "LOG_OUT_FULFILLED":
            return {
                ...session, profile: {
                    ...session.profile,
                    info: null
                }
            };

        case "GET_LOGGED_PROFILE_PENDING":
            return {
                ...session, profile: {
                    ...session.profile,
                    err: commonErr.LOADING
                }
            }

        case "GET_LOGGED_PROFILE_FULFILLED":
            if (action.payload.data.data && action.payload.data.data.profile) {
                return {
                    ...session,
                    profile: {
                        ...session.profile,
                        err: commonErr.SUCCESS,
                        info: action.payload.data.data.profile
                    }
                }
            } else {
                return {
                    ...session,
                    profile: {
                        ...session.profile,
                        err: commonErr.FAIL
                    }
                }
            }

        default:
            return session;
    }
}
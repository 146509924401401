import { combineReducers } from "redux"
import carFinding from "./carFindingRed"
import session from "./sessionRed"
import appInit from "./appInitRed"
import listF2 from "./listF2Red"

export default combineReducers({
    carFinding,
    session,
		appInit,
		listF2
});
import React from "react" 

export const LoadingInline = () => {

	return (
		<div className="loading-inline center p-4">
			<div className="dot-pulse"></div>
		</div>
	)
}

export class LoadingPage extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return <div className="preloader-backdrop">
					<div className="page-preloader">Loading</div>
				</div>
    }
}
import React from "react";

import bg from "../../static/images/install-app/bg-install-app.png";
import img1 from "../../static/images/install-app/install-app-1.png";
import img2 from "../../static/images/install-app/install-app-2.png";
import appstore from "../../static/images/install-app/install-app-appstore.png";
import playstore from "../../static/images/install-app/install-app-playstore.png";
import video from "../../static/images/install-app/video.mp4";

import { installAndroid, installIOS } from "../../constant/routes";

const InstallApp = () => {
	return (
		<div className="mioto-layout">
			<section className="body">
				<div className="install-app-page" style={{ backgroundImage: `url(${bg})` }}>
					<div className="install-app-sec space xl">
						<div className="m-container">
							<h3 className="textAlign-center">Mioto - Cùng Bạn Đến Mọi Hành&nbsp;Trình</h3>
							<div className="install-app-container">
								<div className="left">
									<video controls autoPlay loop>
										<source src={video} type="video/mp4" />
									</video>
								</div>
								<div className="right">
									<img src={img1} />
									<img src={img2} />
									<div className="wrap-os">
										<a href={installAndroid} target="_blank">
											<img src={playstore} />
										</a>
										<a href={installIOS} target="_blank">
											<img src={appstore} />
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default InstallApp;

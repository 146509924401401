import axios from "axios"

import { devMode } from "./base"
const baseDomain = `https://m-car${devMode ? "-dev" : ""}.mioto.vn`
const commonDomain = `https://m-common${devMode ? "-dev" : ""}.mioto.vn`

export function getLandingPage() {
    return axios({
        withCredentials: true,
        method: "GET",
        url: `${baseDomain}/landingpage`
    })
}
export function getHomePage() {
    return axios({
        withCredentials: true,
        method: "GET",
        url: `${baseDomain}/homepage/sd`
    })
}
export function getHomePageWithDriver() {
    return axios({
        withCredentials: true,
        method: "GET",
        url: `${baseDomain}/homepage/wd`
    })
}
export function getCity(city) {
    return axios({
        withCredentials: true,
        method: "GET",
        url: `${baseDomain}/city/detail/sd?city=${city}`
    })
}
export function getCityWithDriver(city) {
    return axios({
        withCredentials: true,
        method: "GET",
        url: `${baseDomain}/city/detail/wd?city=${city}`
    })
}

export function submitFormOwnerRegister(data) {
    return axios({
        withCredentials: true,
        method: "POST",
        url: `${commonDomain}/owner/register?name=${data.name}&phoneNumber=${data.phoneNumber}&city=${data.city}&email=${data.email}&isRental=${data.isRental}&reference=${data.reference}&carName=${data.carName}&stype=${data.stype}`
    })
}

export function genCodePromo() {
    return axios({
        withCredentials: true,
        method: "POST",
        url: `${commonDomain}/act/gen`
    })
}
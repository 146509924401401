import moment from "moment"

import { commonErr } from "../components/common/errors"
import { getHCMCityCoordinates, wdModes } from "../components/common/common"

const defMinMiles = 1;
const defRadius = 50 / 1.60934;

export default function CarFindingRed(carFinding = {
    err: commonErr.INNIT,
    viewMode: 0,
		filter: {
        // platformType: 0,
        //for list
        fromCarId: "",
        pos: 0,
        ver: 0,

        //for filter
        address: "Ho Chi Minh City, Vietnam",
        addressArray: ["Ho Chi Minh City, Vietnam"],
        wdMode: wdModes.CITY_TOUR,
        wdOneWay: false, 
        lat: getHCMCityCoordinates.lat,
        lng: getHCMCityCoordinates.lng,
        maxPrice: 3000,
        startDate: moment().add(1, "days").hour(21).minute(0).second(0).millisecond(0),
        endDate: moment().add(2, "days").hour(20).minute(0).second(0).millisecond(0),
        seat: 0,
        sort: "op",
        vehicleType: 0,
        vehicleMake: 0,
        vehicleModel: 0,
        yearFrom: 0,
        yearTo: 0,
        instantBooking: false,
        directDelivery: false,
        fuelSize: 0,
        features: [],
        limitKM: 550,
        limitKMPrice: 15,
        dPrice: 20,
        fuel: 0,
        fuelC: 0,
        pVerified: 0,
        minYear: 2000,
        maxYear: moment().year().valueOf(),
        minSeat: 2,
        maxSeat: 16,
        radius: defRadius,
        minMiles: defMinMiles,
        carGroups: []
    }
}, action) {
    switch (action.type) {
        case "SET_VIEW_MODE": {
            return {
                ...carFinding,
                viewMode: action.payload
            }
        }

        case "SET_FILTER": {
            return {
                ...carFinding,
                filter: action.payload
            }
        }

        case "SEARCH_CAR_LIST_PENDING": {
            return {
                ...carFinding,
                err: commonErr.LOADING
            }
        }

        case "SEARCH_CAR_LIST_REJECTED": {
            return {
                ...carFinding,
                err: commonErr.FAIL
            }
        }

        case "SEARCH_CAR_LIST_FULFILLED": {
            const data = action.payload.data.data;
            if(data) {
            return {
                ...carFinding,
                err: commonErr.SUCCESS,
                carGroups: data.carGroups,
                wd: data.wd
                // total: data.totalCars,
                // more: data.more,
                // unchanged: data.unchanged,
                // counters: data.counters,
                // filter: {
                //     ...carFinding.filter,
                //     minMiles: data.minMiles > defMinMiles ? data.minMiles : defMinMiles
                // }
            }
            }
            return { ...carFinding}
        }

        case "SEARCH_CAR_LIST_MORE_FULFILLED": {
            const data = action.payload.data.data;
       
            return {
                ...carFinding,
                err: commonErr.SUCCESS,
                carGroups: carFinding.carGroups.concat(data.carGroups),
                // total: data.totalCars,
                // more: data.more,
                // unchanged: data.unchanged,
                // counters: data.counters
            }
        }

        default:
            return carFinding;
    }
}
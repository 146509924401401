import React from "react";

import { IcFooterFacebook, IcFooterTiktok, IcFooterZalo } from "../common/svg";

import logo_full from "../../static/images/logo/logo-full.png";
import bct from "../../static/images/footer/government/bocongthuong.png";
import momo from "../../static/images/footer/payments/momo.png";
import vnpay from "../../static/images/footer/payments/vnpay.png";
import alepay from "../../static/images/footer/payments/alepay.png";
import visa from "../../static/images/footer/payments/visa.png";
import zalopay from "../../static/images/footer/payments/zalopay.png";

const Footer = () => {
	return (
		<section className="footer">
			<div className="m-container">
				<div className="footer-about">
					<div className="footer-info">
						<a href="https://www.mioto.vn/" className="wrap-svg logo">
							<img src={logo_full} />
						</a>
						<div className="phone-mail">
							<a className="item" href="tel:19009217">
								<p className="main">1900 9217</p>
								<p>Hỗ trợ 7AM - 10PM</p>
							</a>
							<a className="item" href="mailto:contact@mioto.vn">
								<p className="main">contact@mioto.vn</p>
								<p>Đặt câu hỏi cho Mioto</p>
							</a>
						</div>
						<div className="footer-contact">
							<a className="wrap-svg" target="_blank" href="https://www.facebook.com/mioto.vn/">
								<IcFooterFacebook />
							</a>
							<a className="wrap-svg" target="_blank" href="https://www.tiktok.com/@miotovn">
								<IcFooterTiktok />
							</a>
							<a className="wrap-svg" target="_blank" href="https://zalo.me/1377158618768832739">
								<IcFooterZalo />
							</a>
							{/* <a className="wrap-svg" target="_blank" href="https://www.instagram.com/miotovn/">
								<IcFooterInstagram />
							</a>
							<a className="wrap-svg" target="_blank" href="https://www.aedin.com/company/miotovn/">
								<IcFooteraedin />
							</a>
							<a className="wrap-svg" target="_blank" href="https://twitter.com/miotovn">
								<IcFooterTwitter />
							</a>
							<a className="wrap-svg" target="_blank" href="https://www.youtube.com/channel/UCUbuFZt8RcIZUX4qC_BaV4Q">
								<IcFooterYoutube />
							</a>
							<a className="wrap-svg" target="_blank" href="https://www.pinterest.com/miotovietnam/">
								<IcFooterPinterest />
							</a> */}
						</div>
					</div>
					<div className="footer-path four-item">
						<div className="item">
							<p className="main">Chính Sách</p>
							<div className="item-sub">
								<a href="https://www.mioto.vn/aboutus">Giới thiệu về Mioto</a>
								<a href="https://www.mioto.vn/privacy">Chính sách và quy&nbsp;định</a>
								<a href="https://www.mioto.vn/regu">Quy chế hoạt&nbsp;động</a>
								<a href="https://www.mioto.vn/personalinfo">Bảo mật thông&nbsp;tin</a>
								<a href="https://www.mioto.vn/resolveconflic">Giải quyết tranh&nbsp;chấp</a>
							</div>
						</div>
						<div className="item">
							<p className="main">Tìm Hiểu Thêm</p>
							<div className="item-sub">
								<a href="https://www.mioto.vn/howitwork">Hướng dẫn chung</a>
								<a href="https://www.mioto.vn/bookinghowto">Hướng dẫn đặt&nbsp;xe</a>
								{/* <a href="https://www.mioto.vn/ownerguide">Hướng dẫn dành cho chủ&nbsp;xe</a> */}
								<a href="https://www.mioto.vn/paymenthowto">Hướng dẫn thanh&nbsp;toán</a>
							</div>
						</div>
						<div className="item">
							<p className="main empty">&nbsp;</p>
							<div className="item-sub">
								<a href="https://www.mioto.vn/faqs">Hỏi và trả&nbsp;lời</a>
								<a href="https://www.mioto.vn/blogs">Mioto Blog</a>
								<a href="https://www.mioto.vn/career">Tuyển dụng</a>
							</div>
						</div>
						<div className="item unset">
							<p className="main">Đối Tác</p>
							<div className="item-sub">
								<a href="https://www.mioto.vn/owner/register">Đăng ký chủ&nbsp;xe Mioto</a>
								<a href="https://www.mioto.vn/registergps">Đăng ký GPS MITRACK&nbsp;4G</a>
								{/* <a href="https://www.mioto.vn/insurance">
									Đăng ký Bảo hiểm vật chất &amp; Bảo hiểm
									TNDS xe ô&nbsp;tô
								</a> */}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="line"> </div>
			<div className="m-container">
				<div className="footer-about">
					<div className="footer-info">
						<div className="phone-mail">
							<p className="item">© Công ty Cổ Phần Mioto Asia</p>
						</div>
					</div>
					<div className="footer-path three-item">
						<div className="item">
							<div className="item-sub">
								<p>Số GCNĐKKD: 0317307544</p>
							</div>
						</div>
						<div className="item">
							<div className="item-sub">
								<p>Ngày cấp: 24-05-22</p>
							</div>
						</div>
						<div className="item width-50">
							<div className="item-sub">
								<p>Nơi cấp: Sở Kế hoạch và Đầu tư TPHCM</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="line"> </div>
			<div className="m-container">
				<div className="footer-about">
					<div className="footer-info">
						<div className="phone-mail address">
							<p className="item">
								Địa chỉ: Văn phòng 02, Tầng 08, Tòa nhà Pearl Plaza, Số 561A Điện Biên Phủ, Phường 25, Quận Bình Thạnh,
								Thành phố Hồ Chí Minh, Việt Nam.
							</p>
						</div>
					</div>
					<div className="footer-path three-item">
						<div className="item">
							<div className="item-sub">
								{/* <a>Mã số thuế: 0317307544</a> */}
								<p>Tên TK: CT CP MIOTO ASIA</p>
							</div>
						</div>
						<div className="item">
							<div className="item-sub">
								{/* <a href="mailto:contact@mioto.vn">Mail: contact@mioto.vn</a> */}
								<p>Số TK: 102-989-1989</p>
							</div>
						</div>
						<div className="item width-50">
							<div className="item-sub">
								{/* <a href="tel:19009217">Điện thoại: 1900 9217 (9AM-9PM T2-T7)</a> */}
								<p>Ngân hàng Vietcombank - CN Tân Định</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="line line-white" />
			<div className="m-container">
				<div className="footer-about">
					<div className="footer-info empty">
						<div className="phone-mail">
							<a className="logo-government" target="_blank" href="http://online.gov.vn/Home/WebDetails/98465">
								<img src={bct} />
							</a>
						</div>
					</div>
					<div className="footer-path d-flex-align-center two-item">
						<div className="item">
							<div className="item-sub">
								<p>Phương thức thanh toán</p>
							</div>
						</div>
						<div className="item">
							<div className="payment">
								<img src={momo} />
								<img src={vnpay} />
								<img src={alepay} />
								<img src={visa} />
								<img src={zalopay} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Footer;

import React from "react";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import cookie from "react-cookies";

import { IcCopyGreen } from "../common/svg";

import { commonErr } from "../common/errors";
import { MessageBox } from "../common/messagebox";
import { genCodePromo } from "../../model/common";

// import AppStore from "../../static/img/app-install/app-store.png";
// import GooglePlay from "../../static/img/app-install/google-play.png";
// import Banner from "../../static/img/ads-promo/ads-1.png";

import { IcFB, IcInstagram, IcTwitter, IcTiktok, IcLinkedin, IcYoutube, IcPinterest } from "../common/svg";
import { LoadingPage } from "../common/loading";
import moment from "moment";

export default class AdsPromo extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			err: commonErr.INNIT,
			copied: false,
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		const cookieCode = cookie.load("_mcode");
		const cookieDateFrom = cookie.load("_mfrom");
		const cookieDateTo = cookie.load("_mto");

		if (cookieCode && cookieDateFrom && cookieDateTo) {
			this.setState({
				err: commonErr.SUCCESS,
				code: cookieCode,
				validDateFrom: parseInt(cookieDateFrom),
				validDateTo: parseInt(cookieDateTo),
			});
		} else {
			genCodePromo().then((resp) => {
				if (resp.data.error >= commonErr.SUCCESS) {
					this.setState({
						err: resp.data.error,
						code: resp.data.data.code,
						validDateFrom: resp.data.data.validDateFrom,
						validDateTo: resp.data.data.validDateTo,
					});
					cookie.save("_mcode", resp.data.data.code, { path: "/" });
					cookie.save("_mfrom", resp.data.data.validDateFrom, { path: "/" });
					cookie.save("_mto", resp.data.data.validDateTo, { path: "/" });
				} else {
					this.setState({
						err: resp.data.error,
						errMsg: resp.data.errorMessage,
					});
				}
			});
		}
	}

	trackPromoAT = (code) => {
		const trackingId = cookie.load("_aff_sid") || "";
		if (trackingId === "") return;

		window.AT.init({ campaign_id: 2761, is_reoccur: 1, is_lastclick: 1 });

		let accesstrade_order_info = {
			order_id: code,
			amount: 0,
			discount: 0,
			extra__phone: "default",
			order_items: [
				{
					itemid: "default",
					quantity: 1,
					price: 0,
					catid: "cpql",
				},
			],
		};
		window.AT.track_order(accesstrade_order_info);
	};

	hideCopyBox = () => {
		this.setState({
			copied: false,
		});
	};

	render() {
		const { err, code, validDateFrom, validDateTo } = this.state;
		var content;
		if (err === commonErr.INNIT || err === commonErr.FAIL) {
			content = <LoadingPage />;
		} else {
			content = (
				<React.Fragment>
					<div className="ads-promo__sect">
						<div className="img-container">
							<div className="wrapper-img">
								<img className="banner" src={Banner} />
								<p className="promo-code">{code}</p>
								<p className="deadline">
									{moment(validDateFrom).format("DD/MM")} - {moment(validDateTo).format("L")}
								</p>
								<CopyToClipboard text={code} onCopy={() => this.setState({ copied: true })}>
									<div className="icon">
										<IcCopyGreen />
									</div>
								</CopyToClipboard>
							</div>

							{/* <Link className="btn btn--m btn-ads" target="_blank" onClick={() => this.trackPromoAT(code)} to={{
							// pathname: `/find/filter`,
							search: `promo=${code}`
						}}><span>ĐẶT XE NGAY</span></Link> */}
							<a
								className="btn btn--m btn-ads"
								href={`https://www.mioto.vn?promo=${code}`}
								target="_blank"
								onClick={() => this.trackPromoAT(code)}
								rel="noopener noreferrer">
								ĐẶT XE NGAY
							</a>
							<p className="or">Hoặc</p>
							<div className="app-install">
								<a href="https://mioto.app.link/android_mioto" target="_blank" rel="noopener noreferrer">
									<img src={GooglePlay} />
								</a>
								<a href="https://mioto.app.link/ios_mioto" target="_blank" rel="noopener noreferrer">
									<img src={AppStore} />
								</a>
							</div>
							<p className="cpr">Copyright © 2022. Công ty Cổ phần Mioto Asia.</p>
						</div>
						<div className="social">
							<a
								href="https://www.facebook.com/mioto.vn/"
								className="func-social"
								target="_blank"
								rel="noopener noreferrer">
								<IcFB />
							</a>
							<a
								href="https://www.tiktok.com/@miotovn"
								className="func-social"
								target="_blank"
								rel="noopener noreferrer">
								<IcTiktok />
							</a>
							<a
								href="https://www.instagram.com/miotovn/"
								className="func-social"
								target="_blank"
								rel="noopener noreferrer">
								<IcInstagram />
							</a>
							<a
								href="https://www.linkedin.com/company/mioto/"
								className="func-social"
								target="_blank"
								rel="noopener noreferrer">
								<IcLinkedin />
							</a>
							<a href="https://twitter.com/miotovn" className="func-social" target="_blank" rel="noopener noreferrer">
								<IcTwitter />
							</a>
							<a
								href="https://www.youtube.com/channel/UCUbuFZt8RcIZUX4qC_BaV4Q"
								className="func-social"
								target="_blank"
								rel="noopener noreferrer">
								<IcYoutube />
							</a>
							<a
								href="https://www.pinterest.com/miotovietnam/"
								className="func-social"
								target="_blank"
								rel="noopener noreferrer">
								<IcPinterest />
							</a>
						</div>
					</div>
					<MessageBox
						error={commonErr.SUCCESS}
						show={this.state.copied}
						hideModal={this.hideCopyBox}
						message="Đã sao chép mã giảm giá vào bộ nhớ. Đặt xe ngay để nhận ưu đãi hấp dẫn."
					/>
				</React.Fragment>
			);
		}

		return (
			<div className="page-wrapper" style={{ background: "#ECFFFA" }}>
				{content}
			</div>
		);
	}
}

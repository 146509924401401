import moment from 'moment'
import { commonErr } from "../components/common/errors"

export default function listF2Red(listF2 = {
		err: commonErr.INNIT,
		filter: {
			time: 1,
			timeInSeconds: moment().set({
				hour: 0,
				minute: 0,
				second: 0,
				milliseconds: 0
			})
		},
		listF2: [],
		isF1: false
}, action) {
	
	switch (action.type) {
				case "SET_F2_FILTER": {
						return {
								...listF2,
								filter: action.payload
						}
				}
        case "LIST_F2_FULFILLED":
						if (action.payload.data.error >= commonErr.SUCCESS && action.payload.data.data) {
							return {
								...listF2,
								err: action.payload.data.error,
								isF1: action.payload.data.data.f2.length > 0,
								listF2: action.payload.data.data.f2
							};
						}
            return listF2;

        case "LIST_F2_PENDING":
            return {
                ...listF2,
                err: commonErr.LOADING
            }

        case "LIST_F2_REJECTED":
            return {
                ...listF2,
                err: commonErr.FAIL
            }

        default:
            return listF2;
    }
}
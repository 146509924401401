import React from "react";

import Header from "../common/header";
import Footer from "../common/footer";

import empty_page from "../../static/images/empty/empty-page.svg";

const NotFound = () => {
	return (
		<div className="mioto-layout">
			<Header />
			<section className="body">
				<div className="page-not-found">
					<div className="empty-container">
						<img src={empty_page} />
						<p>Trang không tồn tại</p>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};

export default NotFound;

import React from "react"
import ReactDom from "react-dom"

import App from "./App"

import "./static/css/custom.css"
import "./static/css/main.css"


const app = document.getElementById("root")

ReactDom.render(<App />, app);
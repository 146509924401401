import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";

import * as ROUTES from "./constant/routes";

import moment from "moment";
import store from "./store";
import OwnerRegister from "./components/carowner/ownerregister";
import AdsPromo from "./components/adspromo/adspromo";
import InstallApp from "./components/installapp/installapp";

import NotFound from "./components/common/notfound";

const App = () => {
	//localize the calendar in app
	moment.locale("vn", {
		months: "Tháng 1_Tháng 2_Tháng 3_Tháng 4_Tháng 5_Tháng 6_Tháng 7_Tháng 8_Tháng 9_Tháng 10_Tháng 11_Tháng 12".split(
			"_"
		),
		monthsShort: "Th. 1_Th. 2_Th. 3_Th. 4_Th. 5_Th. 6_Th. 7_Th. 8_Th. 9_TH. 10_Th. 11._Th. 12.".split("_"),
		monthsParseExact: true,
		weekdays: "Chủ nhật_Thứ 2_Thứ 3_Thứ 4_Thứ 5_Thứ 6_Thứ 7".split("_"),
		weekdaysShort: "CN_T. 2_T. 3_T. 4_T. 5_T. 6._T. 7".split("_"),
		weekdaysMin: "CN_T.2_T.3_T.4_T.5_T.6_T.7".split("_"),
		weekdaysParseExact: true,
		longDateFormat: {
			LT: "HH:mm",
			LTS: "HH:mm:ss",
			L: "DD/MM/YYYY",
			LL: "D MMMM YYYY",
			LLL: "D MMMM YYYY HH:mm",
			LLLL: "dddd D MMMM YYYY HH:mm",
		},
		calendar: {
			sameDay: "[Cùng ngày] LT",
			nextDay: "[Ngày mai] LT",
			nextWeek: "dddd [Tuần tới] LT",
			lastDay: "[Hôm qua] LT",
			lastWeek: "dddd [Tuần rồi] LT",
			sameElse: "L",
		},
		relativeTime: {
			future: "%s tới",
			past: "%s trước",
			s: "%d giây",
			m: " 1 phút",
			mm: "%d phút",
			h: " 1 giờ",
			hh: "%d giờ",
			d: " 1 ngày",
			dd: "%d ngày",
			M: " 1 tháng",
			MM: "%d tháng",
			y: " 1 năm",
			yy: "%d năm",
		},
		dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
		ordinal: function (number) {
			return number + (number === 1 ? "er" : "e");
		},
		meridiemParse: /PD|MD/,
		isPM: function (input) {
			return input.charAt(0) === "M";
		},
		// In case the meridiem units are not separated around 12, then implement
		// this function (look at locale/id.js for an example).
		// meridiemHour : function (hour, meridiem) {
		//     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
		// },
		meridiem: function (hours, minutes, isLower) {
			return hours < 12 ? "AM" : "PM";
		},
		week: {
			dow: 1, // Monday is the first day of the week.
			doy: 4, // The week that contains Jan 4th is the first week of the year.
		},
	});

	return (
		<Provider store={store}>
			<BrowserRouter>
				<LastLocationProvider watchOnlyPathname={true}>
					<Switch>
						{/* <Route exact path={ROUTES.ADS_PROMO} component={AdsPromo} /> */}
						<Route exact path={ROUTES.OWNER_REGISTER} component={OwnerRegister} />
						<Route exact path={ROUTES.INSTALL_APP} component={InstallApp} />
						<Route component={NotFound} />
					</Switch>
				</LastLocationProvider>
			</BrowserRouter>
		</Provider>
	);
};

export default App;
